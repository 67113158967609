import React, { useEffect, useState } from "react";
import SearchFilter from "./components/filter";
import { useMutation, useQuery } from "react-query";
import { BookmarkService, DocService } from "../../../services/dataService";
import ArticleCard from "./components/articleCard";
import ArticleView from "./components/articleView";
import { Button, Checkbox, message, Pagination, Popover, Spin } from "antd";
import BookMarkModal from "../../components/bookMarkModal";
import {
  Bookmark01Icon,
  Download04Icon,
  Edit02Icon,
  MoreVerticalCircle01Icon,
  Tick02Icon,
  Upload02Icon,
} from "../../components/icons";
import ImportArticleModal from "../../components/articleImport";
import BookMarkEdit from "../../components/bookMarkEdit";
import parsePubMedQuery from "../../../components/searchParser";
import usePersistedState from "use-persisted-state";
import { useFilters } from "../../../hooks/filters";

// Persisted states
const usePersistedSearch = usePersistedState("reboot-search");
const usePersistedFilter = usePersistedState("reboot-filter");
const usePersistedBookmarks = usePersistedState("reboot-bookmarks");

const BookmarkView = ({ visible, setVisible, selections, setSelections }) => {
  const onSelectBookmark = async (bookmarkId) => {
    message.loading("Adding to bookmark...");
    await BookmarkService.addItems(
      Object.keys(selections).map((id) => ({
        item_id: id,
        bookmark_id: bookmarkId,
      }))
    );
    message.destroy();
    message.success("Added to bookmark");
    setSelections({});
    setVisible(false);
  };

  return (
    <BookMarkModal
      visible={visible}
      setVisible={setVisible}
      setSelectedBookmark={onSelectBookmark}
    />
  );
};

const LinkButton = ({ children, ...props }) => (
  <button
    className="text-sm text-blue-500 hover:text-blue-600 flex gap-2 items-center"
    {...props}
  >
    {children}
  </button>
);

export default function Dashboard() {
  const [selections, setSelections] = useState({});
  const [selectedBookmarks, setSelectedBookmarks] = useState([]);
  const [bookmarkView, setBookmarkView] = useState(false);
  const [currentSearch, setCurrentSearch] = useState("");
  const [importFlag, setImportFlag] = useState(false);
  const [editBookmarksFlag, setEditBookmarksFlag] = useState(false);
  const { filters, search } = useFilters();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const docs = useQuery(
    ["docs", search, filters, page, pageSize, selectedBookmarks],
    () => {
      // Check if search query has pmid (all ints)
      let pmid = null;
      if (/^\d+$/.test(search.trim())) {
        pmid = search.trim();
      }

      return DocService.search({
        search: pmid ? null : search,
        filters: JSON.stringify(filters),
        parsedQuery: pmid ? null : parsePubMedQuery(search),
        page,
        pmids: pmid ? pmid : null,
        size: pageSize,
        selected_bookmarks: selectedBookmarks.join(","),
      })
    }
  );

  const exportResults = useMutation(() => {
    let parsedQuery = null;
    if (search) {
      parsedQuery = parsePubMedQuery(search);
    }
    let pmids = null;
    if (/^\d+$/.test(search.trim())) {
      pmids = search.trim();
    }
    return DocService.exportToExcel({
      search: search,
      filters: filters ? JSON.stringify(filters) : undefined,
      parsedQuery: parsedQuery ? JSON.stringify(parsedQuery) : undefined,
      pmids: pmids ? pmids : undefined,
      selected_bookmarks: selectedBookmarks.join(","),
    });
  });

  const onExport = () => {
    message.loading("Exporting results...");
    exportResults.mutateAsync().then((res) => {
      const link = window.URL.createObjectURL(res);
      const a = document.createElement("a");
      a.href = link;
      a.download = "export.xlsx";
      a.click();
      message.destroy();
      message.success("Exported results");
    });
  };

  const myBookmarks = useQuery(
    ["bookmarks"],
    () =>
      BookmarkService.getAll({
        mine: true,
      }),
    {}
  );

  return (
    <>
      <BookMarkEdit
        visible={editBookmarksFlag}
        onCancel={() => setEditBookmarksFlag(false)}
      />
      <ImportArticleModal
        visible={importFlag}
        onCancel={() => setImportFlag(false)}
      />
      {bookmarkView && (
        <BookmarkView
          visible={bookmarkView}
          setVisible={() => setBookmarkView(false)}
          selections={selections}
          setSelections={setSelections}
        />
      )}
      <div className="flex flex-col gap-2 h-full">
        <div className="flex justify-between">
          <div className="flex-1 max-w-[840px] flex gap-2">
            <div className="flex-1">
              <SearchFilter
                
              />
            </div>
            <div>
              <Popover
                content={
                  <div className="flex flex-col gap-2 p-2">
                    {myBookmarks.data?.results.map((bookmark) => (
                      <div
                        key={bookmark._id}
                        onClick={() => {
                          setSelectedBookmarks((prev) => {
                            if (prev.includes(bookmark._id)) {
                              return prev.filter((id) => id !== bookmark._id);
                            } else {
                              return [...prev, bookmark._id];
                            }
                          });
                        }}
                        className={`${selectedBookmarks.includes(bookmark._id) ? "text-blue-500" : " text-neutral-500"} p-2 px-6 cursor-pointer flex gap-2 items-center relative`}
                      >
                        {selectedBookmarks.includes(bookmark._id) && (
                          <Tick02Icon className="w-4 h-4 text-blue-500 absolute left-0" />
                        )}
                        {bookmark.name}
                      </div>
                    ))}
                  </div>
                }
              >
                <Button className="flex gap-2 items-center text-neutral-600">
                  <Bookmark01Icon className="w-4 h-4  text-neutral-600" />
                  Bookmark
                  {selectedBookmarks.length > 0 && (
                    <div className="text-xs text-blue-500 bg-blue-50 rounded-full px-2 py-1">
                      {selectedBookmarks.length}
                    </div>
                  )}
                </Button>
              </Popover>
            </div>
          </div>
          <div className="pl-4 flex gap-2 items-center">
            {Object.keys(selections).length > 0 && (
              <button
                className="text-sm text-blue-500  px-2 py-1 bg-blue-50 rounded-lg"
                onClick={() => setBookmarkView(true)}
              >
                {Object.keys(selections).length} selected
              </button>
            )}
            <div className="text-sm text-neutral-500">
              {docs.data?.total.toLocaleString()} results
            </div>
            <Popover
              content={
                <div className="flex flex-col gap-4 p-4">
                  <LinkButton onClick={onExport}>
                    <Download04Icon className="w-4 h-4 text-blue-500 hover:text-blue-600" />
                    Export Results
                  </LinkButton>
                  <LinkButton onClick={() => setImportFlag(true)}>
                    <Upload02Icon className="w-4 h-4 text-blue-500 hover:text-blue-600" />
                    Import Article
                  </LinkButton>
                  <LinkButton onClick={() => setEditBookmarksFlag(true)}>
                    <Edit02Icon className="w-4 h-4 text-blue-500 hover:text-blue-600" />
                    Edit Bookmarks
                  </LinkButton>
                </div>
              }
              trigger="click"
            >
              <Button>
                <MoreVerticalCircle01Icon className="w-4 h-4  text-neutral-600" />
              </Button>
            </Popover>
          </div>
        </div>

        <div className="flex gap-2 flex-1 overflow-hidden">
          <div className="flex flex-col gap-2 w-[440px] py-4 overflow-y-auto">
            {docs.isLoading && (
              <div className="flex justify-center items-center h-full">
                <Spin />
              </div>
            )}
            {docs.data?.results.map((doc) => (
              <div
                key={doc.id}
                className={`relative cursor-pointer transition-all hover:bg-neutral-50 mx-2 ${
                  selectedDoc?.id === doc.id
                    ? "outline outline-blue-500 rounded-lg"
                    : ""
                }`}
                onClick={() => {
                  setSelectedDoc(doc);
                }}
              >
                <ArticleCard article={doc} />
                <div className="absolute bottom-3 right-3">
                  <Checkbox
                    checked={selections[doc.id]}
                    onChange={(e) => {
                      console.log(e.target.checked);
                      setSelections((prev) => {
                        if (e.target.checked) {
                          return {
                            ...prev,
                            [doc.id]: e.target.checked,
                          };
                        } else {
                          delete prev[doc.id];
                          return {
                            ...prev,
                          };
                        }
                      });
                      e.stopPropagation();
                    }}
                  />
                </div>
              </div>
            ))}
            <Pagination
              current={page}
              pageSize={pageSize}
              total={docs.data?.total}
              onChange={(page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }}
            />
          </div>
          <div className="flex-1 overflow-y-auto">
            {selectedDoc && (
              <ArticleView articleId={selectedDoc.id} fullView={false} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
