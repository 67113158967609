import React from "react";

import {
  Row,
  Col,
  Button,
  Card,
  Tag,
  Typography,
  Layout,
  Menu,
  Anchor,
  Input,
  List,
  Select,
  Modal,
  message,
} from "antd";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";

import axios from "axios";
import remarkGfm from "remark-gfm";
import {
  createBrowserRouter,
  Link,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  EditOutlined,
  DownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import createPersistedState from "use-persisted-state";
import CustomHeader from "../../components/header";
import { BookmarkService } from "../../services/dataService";
import TableView from "../pubmedData/tableView";
import PubDetailView from "../pubmedData/detailView";
import { useAuth } from "../../authContext";
import PubDetailViewV2 from "../pubmedData/detailViewV2";
import { PublicationCard } from "../pubmedDataV2";
import FilterView from "./filterView";
// import { Header } from "antd/es/layout/layout";
const labelAppState = createPersistedState("bookmarkState");

const BookmarkEdit = ({ enabled, onCancel }) => {
  // Modal to enable bookmark edit or delete
  const [editSelected, setEditSelected] = useState("");
  const [bookmarkName, setBookmarkName] = useState("");
  const auth = useAuth();
  const queryClient = useQueryClient();

  const updateBookmark = useMutation(
    (data) => BookmarkService.update(data._id, data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("bookmarks");
      },
    }
  );

  const deleteBookmark = useMutation((id) => BookmarkService.delete(id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("bookmarks");
    },
  });

  const bookmarks = useQuery(
    ["bookmarks"],
    () =>
      BookmarkService.getAll({
        userId: auth.user.id,
      }),
    {}
  );

  return (
    <Modal
      title="Edit Bookmark"
      visible={enabled}
      onOk={() => {}}
      onCancel={() => {
        onCancel();
      }}
      footer={null}
    >
      {bookmarks.data?.results
        ?.filter((bookmark) => bookmark.owner_id == auth.user.id)
        .map((bookmark) => (
          <div key={bookmark._id}>
            {editSelected == bookmark._id ? (
              <Row>
                <Input
                  value={bookmarkName}
                  onChange={(e) => {
                    setBookmarkName(e.target.value);
                  }}
                ></Input>
                <Button
                  type="link"
                  onClick={() => {
                    updateBookmark.mutate({
                      id: bookmark._id,
                      name: bookmarkName,
                      owner_id: auth.user.id,
                    });
                    setEditSelected("");
                  }}
                >
                  Save
                </Button>
              </Row>
            ) : (
              <Row justify="space-between">
                <Col span={12}>{bookmark.name}</Col>
                <Col>
                  <Button
                    type="link"
                    onClick={() => {
                      setEditSelected(bookmark._id);
                      setBookmarkName(bookmark.name);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    type="link"
                    danger
                    onClick={() => {
                      deleteBookmark.mutate(bookmark._id);
                    }}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        ))}
    </Modal>
  );
};

const NewBookmark = ({ enabled, onCancel }) => {
  const [bookmarkName, setBookmarkName] = useState("");
  const auth = useAuth();
  const queryClient = useQueryClient();

  const createBookmark = useMutation((data) => BookmarkService.create(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("bookmarks");
      message.success("Bookmark created successfully");
      onCancel();
    },
  });

  return (
    <Modal
      title="New Bookmark"
      visible={enabled}
      onOk={() => {}}
      onCancel={() => {
        onCancel();
      }}
      footer={null}
    >
      <Row>
        <Input
          value={bookmarkName}
          onChange={(e) => {
            setBookmarkName(e.target.value);
          }}
        ></Input>
        <Button
          type="link"
          onClick={() => {
            createBookmark.mutate({
              name: bookmarkName,
              owner_id: auth.user.id,
            });
            setBookmarkName("");
          }}
          style={{
            paddingTop: "10px",
          }}
        >
          Save
        </Button>
      </Row>
    </Modal>
  );
};

const BookmarkPage = () => {
  const queryClient = useQueryClient();
  const [tab, setTab] = useState("1");

  const [state, setState] = labelAppState({
    loading: true,
    page: 1,
    tableView: false,
    search: "",
    selectedBookmark: null,
  });

  const [filters, setFilters] = useState({});
  const ref = useRef(null);
  useEffect(() => {}, []);

  const bookmarks = useQuery(
    ["bookmarks"],
    () => BookmarkService.getAll({}),
    {}
  );

  const bookmarkItems = useQuery(
    ["bookmarkItems", state.selectedBookmark, state.page, filters],
    () =>
      BookmarkService.getItems(state.selectedBookmark, {
        page: state.page,
        size: state.size,
        query: filters?.query,
        filters: JSON.stringify(filters?.otherFilters),
        parsedQuery: JSON.stringify(filters?.parsedQuery),
      }),
    {
      onSuccess: (data) => {
        // setState((prev) => ({
        //     ...prev,
        // }))
      },
    }
  );

  const auth = useAuth();
  const bookmarkOwner = bookmarks.data?.results?.find(
    (bookmark) => state.selectedBookmark == bookmark._id
  )?.owner_id;
  const isUserBookmarkSelected = bookmarkOwner == auth.user.id;

  const bookmarkRemove = useMutation(
    (id) => BookmarkService.deleteItem( id),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("bookmarkItems");
        message.success("Bookmark item removed successfully");
        // If the selected item is removed, clear the selected item
       
      },
      onError: (error) => {
        message.error("Error removing bookmark item");
      },
    }
  );
  const exportBookmark = useMutation((data) =>
    BookmarkService.exportItems(state.selectedBookmark)
  );
  return (
    <div>
      <CustomHeader />
      <BookmarkEdit
        enabled={state.editBookmark}
        onCancel={() => setState({ editBookmark: false })}
      />
      <NewBookmark
        enabled={state.newBookmark}
        onCancel={() => setState({ newBookmark: false })}
      />

      <div style={{ margin: "0px 2rem" }}>
        <Row>
          <Col span={24}>
            <div style={{ padding: "1rem 0px" }}>
              <div style={{ float: "right" }}>
                {/* <Button
                  type="primary"
                  onClick={() => {
                    setState({ selectedBookmark: null });
                  }}
                >
                  Add Bookmark
                </Button> */}
                <Select
                  style={{ width: "200px" }}
                  placeholder="Select Bookmark"
                  value={state.selectedBookmark}
                  onChange={(value) => {
                    setState(prev => ({ ...prev, selectedBookmark: value }));
                  }}
                >
                  {bookmarks.data?.results?.map((bookmark) => (
                    <Select.Option value={bookmark._id} key={bookmark._id}>
                      {bookmark.name}
                    </Select.Option>
                  ))}
                </Select>
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() =>
                    setState((prev) => ({ ...prev, editBookmark: true }))
                  }
                >
                  Edit
                </Button>
                <Button
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={() =>
                    setState((prev) => ({ ...prev, newBookmark: true }))
                  }
                >
                  New
                </Button>
                <Button
                  type="link"
                  onClick={() => exportBookmark.mutate()}
                  loading={exportBookmark.isLoading}
                  icon={<DownloadOutlined />}
                  // onClick={() => setState({ editBookmark: true })}
                >
                  Export
                </Button>
              </div>
              <a
                style={{
                  fontSize: "1.5rem",
                }}
              >
                Bookmarks
              </a>
            </div>

            <div>
              <FilterView 
                onChange={(filters) => {
                  
                  setFilters((prev) => ({
                    ...(prev || {}),
                    ...filters,
                  }));
                }}
                onDisplayChange={(value) => {
                  setState((prev) => ({
                    ...prev,
                    tableView: value === "table",
                    splitView: value === "split",
                  }));
                }}
              />
            </div>
            <div style={{ padding: "1rem 0px" }}>
              {state.tableView ? (
                <Row style={{ width: "100%" }}>
                  <TableView
                    data={bookmarkItems.data?.results}
                    onPageChange={(page, pageSize) => {
                      setState((prevState) => ({
                        ...prevState,
                        page: page,
                        size: pageSize,
                      }));
                    }}
                    total={bookmarkItems.data?.total?.value}
                  />
                </Row>
              ) : state.splitView ? (
                <Row style={{ width: "100%" }}>
                  <Col
                    span={8}
                    style={{
                      width: "100%",
                      height: "90vh",
                      overflowY: "scroll",
                      // padding: "0px 20px",
                    }}
                  >
                    <List
                      pagination={{
                        showSizeChanger: false,
                        onChange: (page, pageSize) => {
                          setState((prevState) => ({
                            ...prevState,
                            page: page,
                            size: pageSize,
                          }));
                        },
                        total: bookmarkItems.data
                          ? bookmarkItems.data.total
                          : 0,
                        current: state.page,
                        pageSize: state.size || 10,
                      }}
                      grid={{
                        gutter: 2,
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 1,
                        xl: 1,
                        xxl: 1,
                      }}
                      dataSource={
                        bookmarkItems.data ? bookmarkItems.data.results : []
                      }
                      // dataSource={labelItems.data}
                      loading={bookmarkItems.isLoading}
                      renderItem={(item, idx) => (
                        <List.Item key={item?._id}>  
                          <div key={item?._id}>
                            {/* <Card
                              style={{ margin: "1rem 0rem" }}
                              onClick={() =>
                                setState((prev) => ({
                                  ...prev,
                                  selected: item,
                                  selectedIdx: idx,
                                }))
                              }
                            >
                              <Tag
                                color="geekblue"
                                onClick={() =>
                                  window.open(
                                    `https://pubmed.ncbi.nlm.nih.gov/${item["pubmed_id"]}/`,
                                    "_blank"
                                  )
                                }
                              >
                                PMID: {item.pubmed_id}
                              </Tag>
                              <br />{" "}
                              <div style={{ marginTop: "0.5rem" }}>
                                {item["title"]}
                              </div>
                            </Card> */}
                            <PublicationCard
                              item={item}
                              onClick={() => {
                                setState((prev) => ({
                                  ...prev,
                                  selected: item,
                                }));
                              }}
                              onTabChange={(tab) => {
                                console.log("Tab change", tab);
                              }}
                              selectedTab={null}
                              showDetailView={false}
                              isSelected={
                                state.selected && state.selected.id == item.id
                              }
                              isSelectable={true}
                              showBookmarkDelete={isUserBookmarkSelected?? true}
                              onBookmarkDelete={() => {
                                bookmarkRemove.mutate(item.bookmark_item_id)
                                // Check if the selected item is removed
                                if (state.selected && state.selected.id == item.id) {
                                  setState((prev) => ({
                                    ...prev,
                                    selected: null,
                                  }));
                                }
                              }
                            }
                            />
                          </div>
                        </List.Item>
                      )}
                    />
                  </Col>
                  <Col
                    span={16}
                    //Set scrollbar width to 0.125 rem
                    style={{
                      width: "100%",
                      height: "90vh",
                      overflowY: "scroll",
                    }}
                  >
                    {state.selected ? (
                      <div style={{ margin: "1.5rem 1.5rem" }}>
                        <div ref={ref}></div>
                        {/* <PubDetailView selected={state.selected} /> */}
                        <PubDetailViewV2
                          itemId={state.selected._id}
                          pubmedId={state.selected.pubmed_id}
                          title={state.selected.title}
                          selected={state.selected}
                        />
                      </div>
                    ) : null}
                  </Col>
                </Row>
              ) : (
                <Row style={{ width: "100%", padding: "0px 10px" }}>
                <List
                  pagination={{
                    pageSizeOptions: ["10", "50", "500", "1000"],
                    showSizeChanger: true,
                    onChange: (page, pageSize) => {
                      setState((prevState) => ({
                        ...prevState,
                        page: page,
                        size: pageSize,
                      }));
                    },
                    total: bookmarkItems.data ? bookmarkItems.data.total : 0,
                    current: state.page,
                    pageSize: state.size || 10,
                  }}
                  grid={{
                    gutter: 2,
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                    xxl: 1,
                  }}
                  dataSource={bookmarkItems.data ? bookmarkItems.data.results : []}
                  // dataSource={labelItems.data}
                  loading={bookmarkItems.isLoading}
                  renderItem={(item, idx) => (
                    <List.Item key={idx}>
                      <div key={item?.pubmed_id}>
                        <PublicationCard
                          item={item}
                          onClick={(item) => {
                            setState((prev) => ({ ...prev, selected: item }));
                          }}
                          onTabChange={(key) => {
                            setTab(key);
                          }}
                          selectedTab={tab}
                          showDetailView={true}
                        />
                      </div>
                    </List.Item>
                  )}
                />
              </Row>
              )
            }
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BookmarkPage;
