import { useState } from 'react';
import { ReferenceService } from '../../../services/dataService';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button, Input, message, Modal, Pagination, Select, Tabs, Typography, Upload } from 'antd';
import { Trash, FilePlus } from 'lucide-react';
import { Markdown } from '../../../components/markdown';
import CustomHeader from '../../../components/header';

const FileSelect = ({ onChange }) => {
  // A file select
  const { data, isLoading, isError } = useQuery(['docs', 'all'], () =>
    ReferenceService.getDocs({
      page: 1,
      page_size: 200,
    }),
  );



  const options = data?.docs.map((item) => ({
    label: item.file_name.replace('.pdf', ''),
    value: item._id,
  }));

  const handleChange = (value) => {
    console.log("S", value);
    onChange(value);
  };

  return <Select mode="tags" options={options} onChange={handleChange}
  filterOption={
    (input, option) => {
      return option.label.toLowerCase().includes(input.toLowerCase());
    }
  }
   className="min-w-48" />;
};

const UploadPDF = ({ visible, onCancel, onConfirm }) => {
  const [state, setState] = useState({
    file: null,
    name: '',
    error: '',
  });

  const uploadMutation = useMutation(ReferenceService.addDoc);

  const handleFileChange = (e) => {
    const file = e.file;

    if (!file) {
      setState((prev) => ({ ...prev, error: 'Please select a file' }));
    } else {
      setState((prev) => ({ ...prev, file, error: '' }));
    }
  };

  const onSubmit = async () => {
    if (!state.file) {
      message.error('Please select a file');
      return;
    }
    if (!state.name) {
      message.error('Please enter a name');
      return;
    }
    let formData = new FormData();
    formData.append('file', state.file);
    formData.append('name', state.name);
    await uploadMutation.mutateAsync(formData);
    message.success('PDF uploaded successfully');
    setState((prev) => ({ ...prev, file: null }));
    onConfirm();
  };

  return (
    <Modal open={visible} onCancel={onCancel} title="Upload PDF" footer={null}>
      <Input
        placeholder="Name"
        value={state.name}
        onChange={(e) => setState((prev) => ({ ...prev, name: e.target.value }))}
        className="mb-4"
      />

      <Upload.Dragger
        onChange={handleFileChange}
        accept="application/pdf,.docx,.doc"
        disabled={state.file}
        beforeUpload={(file) => {
          // setState((prev) => ({ ...prev, file, error: "" }));
          return false;
        }}
        action={null}
        className="flex flex-col items-center justify-center"
      >
        <div className="flex items-center justify-center mb-2">
          <FilePlus className="text-center text-2xl" />
        </div>
        <p className="ant-upload-text text-center">Click or drag file to this area to upload</p>
      </Upload.Dragger>
      {
        <Typography.Text type="danger" style={{ marginTop: '20px' }}>
          {state.error}
        </Typography.Text>
      }

      <div className="flex justify-end mt-4">
        <Button onClick={onSubmit} loading={uploadMutation.isLoading} disabled={!state.file || !state.name}>
          Upload
        </Button>
      </div>
    </Modal>
  );
};

export default function ReferenceCheck({ header = false }) {
  const [uploading, setUploading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [selectedDocs, setSelectedDocs] = useState([]);
  const { data, isLoading, isError } = useQuery(['docs', page], () =>
    ReferenceService.getDocs({
      page,
    }),
  );
  const uploadMutation = useMutation(ReferenceService.addDoc);
  const referenceCheckMutation = useMutation(ReferenceService.getReferenceCheck);
  const queryClient = useQueryClient();

  const handleDelete = (id) => {
    ReferenceService.deleteDoc(id).then(() => {
      queryClient.invalidateQueries('docs');
    });
  };

  const handleClick = (id) => {
    message.loading('Loading...');
    ReferenceService.getPdf(id)
      .then((res) => {
        // Create a blob url and open it in a new tab
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
        message.destroy();
      })
      .catch((err) => {
        console.log(err);
        message.destroy();
        message.error('Error loading PDF');
      });
  };

  console.log("selectedDocs", selectedDocs);

  return (
    <>
      {header && <CustomHeader />}

      <div className="max-w-[1400px] mx-auto px-4">
        <div>
          <UploadPDF
            visible={uploading}
            onCancel={() => setUploading(false)}
            onConfirm={() => {
              setUploading(false);
              queryClient.invalidateQueries('docs');
            }}
          />
          <Tabs>
            <Tabs.TabPane tab="Check References" key="1">
              <div className="flex items-center gap-4">
                <div className="flex-1">
                  <Input
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        referenceCheckMutation.mutate({ search, 
                          reference_ids : selectedDocs.length > 0 ? selectedDocs.join(',') : undefined,
                          deep_search : selectedDocs.length > 0
                         });
                      }
                    }}
                  />
                </div>

                <Button
                  onClick={() => referenceCheckMutation.mutate({ search,
                    reference_ids : selectedDocs.length > 0 ? selectedDocs.join(',') : undefined,
                    deep_search : selectedDocs.length > 0
                   })}
                  loading={referenceCheckMutation.isLoading}
                >
                  Search
                </Button>
              </div>
              <div className="mt-4">
                <span className='text-sm mr-4'> Select References</span>
                <FileSelect
                  placeholder="Select References:"
                  onChange={(value) => {
                    setSelectedDocs(prev => [...value]);
                  }}
                />
              </div>
              <div className="mt-4">
                {referenceCheckMutation.data && (
                  <>
                    <Typography.Title level={5}>Decision</Typography.Title>
                    <Typography.Text type="secondary">
                      {referenceCheckMutation.data?.decision == 'supported' ? (
                        <span className="text-green-500 font-bold text-lg">Supported</span>
                      ) : referenceCheckMutation.data?.decision == 'contradicted' ? (
                        <span className="text-red-500 font-bold text-lg">Contradicted</span>
                      ) : (
                        <span className="text-yellow-500 font-bold text-lg">Inconclusive</span>
                      )}
                    </Typography.Text>
                    <Typography.Title level={5}>Report</Typography.Title>
                    <Typography.Text type="secondary">
                      <Markdown
                        content={referenceCheckMutation.data?.report}
                        linkHandler={(children, href) => {
                          if (href?.startsWith('docId://')) {
                            return (
                              <a
                                onClick={() => {
                                  handleClick(href.replace('docId://', ''));
                                }}
                                className="text-blue-500"
                                target="_blank"
                                rel="noreferrer"
                              >
                                [{children}]
                              </a>
                            );
                          }
                          return (
                            <a href={href} className="text-blue-500">
                              {children}
                            </a>
                          );
                        }}
                      />
                    </Typography.Text>
                  </>
                )}
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Upload References" key="2">
              <div className="flex justify-end pb-4">
                <Button className="flex items-center" onClick={() => setUploading(true)}>
                  {' '}
                  <FilePlus className="w-4 h-4 mr-2" />
                  Upload
                </Button>
              </div>
              <div className="grid grid-cols-4 gap-2">
                {data?.docs.map((doc) => (
                  <div
                    key={doc._id}
                    className="flex items-center justify-between border p-2 rounded-md hover:bg-gray-100 cursor-pointer group"
                    onClick={() => handleClick(doc._id)}
                  >
                    <span className="text-sm font-medium group-hover:underline">
                      {doc.name ?? doc.file_name.replace('.pdf', '').split('_')[1]}
                    </span>
                    <div className="flex items-center gap-2">
                      <Button onClick={() => handleDelete(doc._id)} type="text">
                        <Trash className="w-4 h-4" />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-end mt-4">
                <Pagination
                  current={page}
                  pageSize={24}
                  total={data?.total}
                  onChange={(page) => {
                    setPage(page);
                  }}
                />
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
}
