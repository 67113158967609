import { useQuery } from 'react-query';
import { DocService } from '../../../services/dataService';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import ArticleView from '../dashboard/components/articleView';

const ArticleDetails = () => {
  const { articleId } = useParams();

  return (
    <div className="h-full overflow-y-auto">
      <div className="text-base text-neutral-600">
        <ArticleView articleId={articleId} fullView={true} />
      </div>
    </div>
  );
};

export default ArticleDetails;
