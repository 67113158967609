import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import { Popover } from "antd";

export const Markdown = ({
  content,
  allResults,
  linkHandler
}) => {
  const renderReference = (pmid) => {
    const reference = allResults.find((r) => r.id.toString() === pmid);
    if (!reference) return null;
    return (
      <div>
        <a className="text-blue-500" href={`https://pubmed.ncbi.nlm.nih.gov/${pmid}`} target="_blank" rel="noopener noreferrer">
          PMID: {pmid}
        </a>
        <h2 className="text-base font-medium" >{reference.title}</h2>
        <p className="text-sm text-neutral-600 mt-4" >{reference.abstract}</p>
      </div>
    );
  };

  return (
    <ReactMarkdown
      // remarkPlugins={[remarkGfm]}
      className="text-sm"
      urlTransform={(url) => {
        if (url?.startsWith("docId://")) {
          return url;
        }
        return url;
      }}
      defaultUrlTransform= {(url) => {
        console.log(url)
        return url;
      }}

      
      components={{
        p: ({ children }) => (
          <p className="my-2 text-sm text-gray-600">{children}</p>
        ),
        ol: ({ children }) => (
          <ol className="my-2 list-decimal pl-6 text-sm text-gray-600">
            {children}
          </ol>
        ),
        ul: ({ children }) => (
          <ul className="my-4 list-disc pl-6 text-sm text-gray-600">
            {children}
          </ul>
        ),
        code: ({ children }) => (
          <code className="text-sm text-gray-600 text-wrap">{children}</code>
        ),
        li: ({ children }) => (
          <li className="my-2 list-disc pl-6 text-sm text-gray-600">
            {children}
          </li>
        ),
        table: ({ children }) => (
          <table className="table-auto text-gray-600">{children}</table>
        ),
        td: ({ children }) => (
          <td className="border-b px-4 py-2 text-gray-600">{children}</td>
        ),
        h1: ({ children }) => <h1 className="mt-8 text-base">{children}</h1>,

        h2: ({ children }) => <h2 className="mt-8 text-base">{children}</h2>,
        h3: ({ children }) => (
          <h3 className="mt-6 text-base font-medium">{children}</h3>
        ),
        h4: ({ children }) => (
          <h4 className="mt-4 text-base font-medium">{children}</h4>
        ),
        a: (options) => {
          const { children, node } = options;
          const href = node.properties.href;
          return linkHandler ? linkHandler(children, href) :
          href?.startsWith("pmid://") ? (
            <Popover openDelay={0} content={<div className="w-[600px] h-[300px] overflow-y-auto">
                <div className="w-full h-full">
                  [{renderReference(href?.replace("pmid://", ""))}]
                </div>
              </div>}>
                <button className="cursor-pointer text-blue-500 before:content-['['] after:content-[']'] " onClick={() => {
                  console.log(href?.replace("pmid://", ""));
                }}>
                  [{children}]
                </button>
              
            </Popover>
          ) : (
            <a href={href} className="text-blue-500">
              {children}
            </a>
          )
        }
      }}
    >
      {content}
    </ReactMarkdown>
  );
};
