import { Button, Checkbox, Form, Input, message, Modal, Select, Tabs } from "antd";
import React from "react";
import { DRUG_CLASSES } from "../../constants";
import { BookmarkService, DocService } from "../../services/dataService";
import { useMutation, useQuery } from "react-query";

const ImportArticleModal = ({ visible, onCancel }) => {
  const [loading, setLoading] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const bookmarks = useQuery(
    ["bookmarks"],
    () => BookmarkService.getAll({}),
    {}
  );

  const importMutation = useMutation((data) => {
    return DocService.importArticles(data);
  });

  const importOtherMutation = useMutation((data) => {
    return DocService.importOtherArticles(data);
  });


  const onFinishPubMed = async (values) => {
    console.log("Received values:", values);

    if (!values.multiple_drugs && !values.drugs) {
      message.error("Please select a drug or multiple drugs flag");
      return;
    }

    const data = {
      pmid: values.pubmed_id,
      drugs: values.multiple_drugs ? "Unknown/Multiple drugs" : values.drugs.join("|"),
      drug_class: values.drug_class,
      is_supplementary: values.is_supplementary,
      bookmark: values.bookmark,
    };

    await importMutation.mutateAsync(data);
    message.success("Article imported started!");
    onCancel();

  };

  const onFinishOther = async (values) => {
    // console.log("Received values:", values);

    if (!values.multiple_drugs && !values.drugs) {
      message.error("Please select a drug or multiple drugs flag");
      return;
    }

    const data = {
      doi: values.doi,
      drugs: values.multiple_drugs ? "Unknown/Multiple drugs" : values.drugs.join("|"),
      drug_class: values.drug_class,
      is_supplementary: values.is_supplementary,
      bookmark: values.bookmark,
      other_details: {
        title: values.title,
        abstract: values.abstract,
        authors: values.authors.split(","),
        journal: values.journal,
        publication_year: values.publication_year,
      },
    };

    await importOtherMutation.mutate(data);
    message.success("Article imported started!");

    onCancel();
  };

  const onChange = (e) => {
    // console.log(e);
    if (e.multiple_drugs) {
      setIsDisabled(true);
    } else if (e.multiple_drugs === false) {
      setIsDisabled(false);
    }
  };
  return (
    <div>
      <Modal
        visible={visible}
        onCancel={onCancel}
        destroyOnClose={true}
        footer={null}
        title="Import Article"
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Import from PubMed" key="1">
            <Form
              layout="vertical"
              onFinish={onFinishPubMed}
              onValuesChange={onChange}
            >
              <Form.Item
                label="PubMed ID"
                name="pubmed_id"
                rules={[
                  { required: true, message: "Please input a PubMed ID" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Drug Name"
                name="drugs"
                rules={
                  [
                    //   { required: true, message: "Please input a drug name" },
                  ]
                }
              >
                {/* <Input disabled={isDisabled} />
                 */}
                 <Select
                  mode="tags"
                  disabled={isDisabled} 
                  ></Select>
              </Form.Item>

              <Form.Item label="Drug Class" name="drug_class">
                <Select
                  allowClear
                  options={DRUG_CLASSES.map((drug) => ({
                    label: drug,
                    value: drug,
                  }))}
                />
              </Form.Item>
              <Form.Item
                // label="Multiple Drug"
                name="multiple_drugs"
                valuePropName="checked"
                layout="horizontal"
                style={{
                  marginBottom: "0.5rem",
                }}
              >
                <Checkbox>Multiple Drugs</Checkbox>
              </Form.Item>

              <Form.Item
                // label="Supplemental Data"
                label={undefined}
                name="is_supplementary"
                valuePropName="checked"
              >
                <Checkbox>Supplemental Data</Checkbox>
              </Form.Item>

              <Form.Item
                label="Bookmark"
                name="bookmark"
              >
                <Select
                  options={bookmarks.data && bookmarks.data.results.map((bookmark) => ({
                    label: bookmark.name,
                    value: bookmark._id,
                  }))}
                />
              </Form.Item>
              

              <Form.Item htmlFor="submit">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={importMutation.isLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Other" key="2">
            <Form layout="vertical" onFinish={onFinishOther}
            onValuesChange={onChange}
            >
              <Form.Item
                label="DOI"
                name="doi"
                rules={[{ required: true, message: "Please input a DOI" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Drug Name"
                name="drugs"
                rules={
                  [
                    //   { required: true, message: "Please input a drug name" },
                  ]
                }
              >
                {/* <Input disabled={isDisabled} /> */}
                <Select
                  mode="tags"
                  disabled={isDisabled} 
                  ></Select>
              </Form.Item>

              <Form.Item label="Drug Class" name="drug_class">
                <Select
                  allowClear
                  options={DRUG_CLASSES.map((drug) => ({
                    label: drug,
                    value: drug,
                  }))}
                />
              </Form.Item>

              <Form.Item
                // label="Multiple Drug"
                name="multiple_drugs"
                valuePropName="checked"
                layout="horizontal"
                style={{
                  marginBottom: "0.5rem",
                }}
              >
                <Checkbox>Multiple Drugs</Checkbox>
              </Form.Item>

              <Form.Item
                // label="Supplemental Data"
                label={undefined}
                name="is_supplementary"
                valuePropName="checked"
              >
                <Checkbox>Supplemental Data</Checkbox>
              </Form.Item>

              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "Please input a title" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Abstract"
                name="abstract"
                rules={[
                  { required: true, message: "Please input an abstract" },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                label="Authors"
                name="authors"
                rules={[{ required: true, message: "Please input authors" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Journal"
                name="journal"
                // rules={[{ required: true, message: "Please input a journal" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Publication Year"
                name="publication_year"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input a publication date",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Bookmark"
                name="bookmark"
              >
                <Select
                  options={bookmarks.data && bookmarks.data.results.map((bookmark) => ({
                    label: bookmark.name,
                    value: bookmark._id,
                  }))}
                />
              </Form.Item>

              <Form.Item htmlFor="submit">
                <Button type="primary" htmlType="submit"
                loading={importOtherMutation.isLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default ImportArticleModal;
