import React from 'react';
import {
  Bookmark01Icon,
  Calendar03Icon,
  DnaIcon,
  Edit02Icon,
  Folder01Icon,
  LicenseIcon,
  Link04Icon,
  LinkSquare01Icon,
  Loading03Icon,
  Medicine02Icon,
  News01Icon,
  Pdf01Icon,
  Upload02Icon,
  ArticleIcon,
  UserMultiple02Icon,
  MedicineBottle01Icon,
  Dna01Icon,
  AnalysisTextLinkIcon,
} from '../../../components/icons';
import { Input, message, Tabs } from 'antd';
import PDFDocView from '../../../../pages/pubmedData/DocView';
import BookMarkModal from '../../../components/bookMarkModal';
import UploadPDF from '../../../../pages/pubmedData/importPDF';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { BookmarkService, DocService } from '../../../../services/dataService';
import moment from 'moment';
import { useAuth } from '../../../../authContext';
import ReactJson from 'react-json-view';
import { Copy } from 'lucide-react';

const SUMMARY_KEY = ['objective', 'population_characteristics', 'methods', 'endpoints_measured', 'conclusion'];

const FILTER_KEYS = ['tissue_type'];
const SUMMARY_KEY_LABEL = {
  objective: 'Objective',
  population_characteristics: 'Population Characteristics',
  methods: 'Methods',
  endpoints_measured: 'Endpoints Measured',
  conclusion: 'Conclusions',
};

const KEY_LABEL = {
  endpoints: 'Endpoints',
  relevant_endpoints: 'Relevant Endpoints',
  study_type: 'Study Type',
  primary_tissue_type: 'Primary Tissue Type',
  drugs: 'Drugs Studied',
  cancer_types: 'Cancer Types Studied',
  is_drug_evaluated: 'Is Queried Drug Evaluated',
  num_patients: 'Number of Patients',
  ta: 'Therapeutic Association',
  relevance: 'Relevance',
  is_adhoc: 'Is a Ad-Hoc/Post-Hoc Study',
  is_combination_therapy: 'Combination',
  nccn_cancer_types: 'NCCN Cancer Type',
  old_primary_tissue_type: 'Primary Tissue Type',
  publication_country: 'Publication Country',
};

const ARTICLE_TYPE = ['study_type', 'is_adhoc', 'num_patients', "publication_country"];
const DRUGS = ['is_drug_evaluated', 'is_combination_therapy'];

const RenderKey = ({ label, value }) => {
  if (typeof value === 'boolean') {
    value = value ? 'Yes' : 'No';
  }

  if (Array.isArray(value)) {
    if (value.length === 0) {
      value = <span className="text-sm text-neutral-400">No value extracted</span>;
    } else {
      value = value.map((v) => (
        <span key={v} className="bg-neutral-100 text-neutral-600 px-2 rounded-md text-sm">
          {v}
        </span>
      ));
    }
  }

  if (value === null || value === undefined || value === '') {
    value = <span className="text-sm text-neutral-400">No values extracted</span>;
  }

  return (
    <div className="flex  gap-1 border-b border-neutral-100 py-2 w-[700px]">
      <div className="text-sm font-semibold w-1/2">{label}</div>
      <div className="text-sm w-1/2 flex flex-wrap gap-1">{value}</div>
    </div>
  );
};

const CancerSpecificDetails = ({ article }) => {
  return (
    <div className="flex flex-col ">
      <div className="text-base text-neutral-500 h-full overflow-y-auto w-[700px] flex border-b border-neutral-100 pb-4">
        <div className="text-sm font-semibold basis-1/2">NCCN Cancer Types</div>
        <div className="text-sm basis-1/2 flex flex-wrap gap-1">
          {article.task_specific_details.nccn_cancer_types?.join(', ') }
        </div>
      </div>
      <div className="text-base text-neutral-500 h-full overflow-y-auto mt-4 w-[700px] flex">
        <div className="text-sm font-semibold basis-1/2">Primary Tissue Type</div>
        <div className="text-sm basis-1/2 flex flex-wrap gap-1">{article.task_specific_details.primary_tissue_type}</div>
      </div>
    </div>
  );
};

export const ExtractedDetails = ({ article }) => {
  return (
    <div className="text-base text-neutral-600 h-full overflow-y-auto">
      <div className="mb-12 text-neutral-500">
        <div className="text-sm font-semibold text-blue-500 flex items-center gap-2 mb-2">
          <ArticleIcon className="w-4 h-4 inline text-blue-500" /> Article Details
        </div>
        {ARTICLE_TYPE.map((key) => (
          <RenderKey key={key} label={KEY_LABEL[key]} value={article[key]} />
        ))}
      </div>

      <div className="mb-12">
        <div className="text-sm font-semibold text-blue-500 flex items-center gap-2 mb-4">
          <ArticleIcon className="w-4 h-4 inline text-blue-500" /> Cancer Specific Details
        </div>

        <CancerSpecificDetails article={article} />
      </div>

      <div className="mb-12 text-neutral-500">
        <div className="text-sm font-semibold text-blue-500 mb-2 flex items-center gap-2">
          <MedicineBottle01Icon className="w-4 h-4 inline text-blue-500" />
          Drug Details
        </div>
        <div className="flex w-[700px]">
          <div className="basis-1/2">
            <div className="text-sm font-semibold text-neutral-500 mb-2">Drugs Studied</div>
          </div>
          <div className="flex flex-col gap-1 basis-1/2 ">
            {article.drugs &&
              article.drugs.map((drug, idx) => (
                <div key={idx} className="text-sm text-neutral-500">
                  {drug.name}
                  <span
                    className={`text-xs ml-2 px-2 py-0.5 rounded-md ${
                      drug.is_studied ? 'bg-green-100 text-green-500' : 'bg-yellow-100 text-yellow-500'
                    }`}
                  >
                    {drug.is_studied ? 'Studied' : 'Not Studied'}
                  </span>
                  <span
                    className={`text-xs ml-2 px-2 py-0.5 rounded-md ${
                      drug.is_randomized ? 'bg-green-100 text-green-500' : 'bg-yellow-100 text-yellow-500'
                    }`}
                  >
                    {drug.is_randomized ? 'Randomized' : 'Not Randomized'}
                  </span>
                </div>
              ))}
            {article.drugs && article.drugs.length === 0 && (
              <div className="text-sm text-neutral-500">No drugs studied</div>
            )}
          </div>
        </div>
        <div className=" text-neutral-500">
          {DRUGS.map((key) => (
            <RenderKey key={key} label={KEY_LABEL[key]} value={article[key]} />
          ))}
        </div>
      </div>

      <div className="mb-12">
        <div className="text-sm font-semibold text-blue-500 flex items-center gap-2 mb-2">
          <Dna01Icon className="w-4 h-4 inline text-blue-500" />
          Disease Details
        </div>
        <div className="flex w-[700px]">
          <div className="basis-1/2">
            <div className="text-sm font-semibold text-neutral-500 mb-2">Disease Types</div>
          </div>
          <div className="flex flex-col gap-1 basis-1/2">
            {article.diseases &&
              article.diseases.map((disease, idx) => (
                <div key={idx} className="text-sm text-neutral-500">
                  {disease.name}
                  <span
                    className={`text-xs ml-2 px-2 py-0.5 rounded-md ${
                      disease.is_studied ? 'bg-green-100 text-green-500' : 'bg-yellow-100 text-yellow-500'
                    }`}
                  >
                    {disease.is_studied ? 'Studied' : 'Not Studied'}
                  </span>
                </div>
              ))}
            {article.diseases && article.diseases.length === 0 && (
              <div className="text-sm text-neutral-500">No disease types</div>
            )}
          </div>
        </div>
      </div>

      <div className="mb-12">
        <div className="text-sm font-semibold text-blue-500 flex items-center gap-2 mb-2">
          <AnalysisTextLinkIcon className="w-4 h-4 inline text-blue-500" />
          Outcomes
        </div>
        <div className="mb-4 text-neutral-500">
          <RenderKey label="Therapeutic Association" value={article.impact_type} />
        </div>

        <div className="flex w-[700px]">
          <div className="basis-1/2">
            <div className="text-sm font-semibold text-neutral-500 mb-2">Endpoints</div>
          </div>
          <div className="flex flex-col gap-1 basis-1/2">
            {article.endpoints &&
              article.endpoints.map((endpoint, idx) => (
                <div key={idx} className="text-sm text-neutral-500">
                  {endpoint.name}
                  <span className={`text-xs ml-2 px-2 py-0.5 rounded-md bg-neutral-100 text-neutral-500`}>
                    {endpoint.category}
                  </span>
                </div>
              ))}
            {article.endpoints && article.endpoints.length === 0 && (
              <div className="text-sm text-neutral-500">No endpoints</div>
            )}
          </div>
        </div>
      </div>

      {/* <div className="mb-12">
        <div className="text-sm font-semibold text-neutral-500 flex items-center gap-2 mb-2">
          <AnalysisTextLinkIcon className="w-4 h-4 inline text-neutral-500" />
          Outcomes
        </div>
        {OUTCOMES.map((key) => (
          <RenderKey key={key} label={KEY_LABEL[key]} value={article[key]} />
        ))}
        <div className="text-sm font-semibold mt-4">Endpoints Stats</div>
        {article.endpoint_stats.map((details, idx) => (
          <EndpointStats key={idx} details={details} />
        ))}
      </div> */}
    </div>
  );
};
const Button = ({ children, disabled, onClick, loading }) => {
  return (
    <button
      className={`text-sm px-2 py-1 rounded-md bg-neutral-100 text-neutral-600 transition hover:bg-neutral-200 active:bg-neutral-300 flex items-center gap-2 ${
        disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
      } ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading && <Loading03Icon className="animate-spin" />}
      {children}
    </button>
  );
};

const CommentSection = ({ articleId }) => {
  const auth = useAuth();
  const [commentText, setCommentText] = React.useState('');
  const comments = useQuery(['comments', articleId], () => DocService.getComments(articleId), {});

  const saveComment = useMutation(
    (data) =>
      DocService.addComment(articleId, {
        ...data,
        pubmed_id: articleId,
        comment_by: auth.user.username,
      }),
    {
      onSuccess: () => {
        comments.refetch();
        setCommentText('');
      },
    },
  );
  return (
    <div className="relative h-full overflow-hidden">
      <div className="overflow-y-auto h-full">
        <div className="flex flex-col gap-2  pb-32">
          {comments.data?.results.map((comment, idx) => (
            <div
              key={comment.id}
              className={`flex flex-col gap-1 p-2 border-b ${
                idx === comments.data?.results.length - 1 ? 'border-b-0' : 'border-b'
              }`}
            >
              <div className="text-sm text-neutral-600">{comment.comment}</div>
              <div className="flex gap-3 text-neutral-400">
                <div className="text-xs">{moment(comment.created_at).format('MMM DD, YYYY')}</div>
                <div className="text-xs capitalize">{comment.comment_by}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 bg-white">
        <Input.TextArea value={commentText} onChange={(e) => setCommentText(e.target.value)} className="mb-2 mt-4" />
        <Button onClick={() => saveComment.mutate({ comment: commentText })} loading={saveComment.isLoading}>
          <span className="px-4 py-1 "> Save</span>
        </Button>
      </div>
    </div>
  );
};

const ArticleView = ({ articleId, fullView = false }) => {
  const [bookMarkFlag, setBookMarkFlag] = React.useState(false);
  const [pdfImportFlag, setPdfImportFlag] = React.useState(false);
  const [folderFlag, setFolderFlag] = React.useState(false);
  const [pdfViewFlag, setPdfViewFlag] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('summary');
  const queryClient = useQueryClient();

  const articleDetailQuery = useQuery(['articleDetail', articleId], () => {
    return DocService.get(articleId);
  });

  const articleDetail = articleDetailQuery.data;

  const bookmarkMutation = useMutation((bookmarkId) => {
    return BookmarkService.addItem({
      bookmark_id: bookmarkId,
      item_id: articleId,
    });
  });

  const openPDFViewer = () => {
    setPdfViewFlag(true);
  };

  const onAddToBookmark = async (bookmarkId) => {
    message.loading('Adding to bookmark');
    await bookmarkMutation.mutateAsync(bookmarkId);
    message.destroy();
    message.success('Added to bookmark');
    queryClient.invalidateQueries(['bookmarkItems']);
    setBookMarkFlag(false);
  };

  if (!articleDetail) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {pdfViewFlag && (
        <PDFDocView
          docId={articleDetail.pubmed_id}
          pmid={articleDetail.pubmed_id}
          visible={pdfViewFlag}
          onClose={() => {
            setPdfViewFlag(false);
          }}
        />
      )}
      {pdfImportFlag && (
        <UploadPDF
          itemId={articleId}
          visible={pdfImportFlag}
          onCancel={() => setPdfImportFlag(false)}
          onConfirm={() => {
            setPdfImportFlag(false);
            queryClient.invalidateQueries(['pubmedItem', articleId]);
          }}
        />
      )}
      {bookMarkFlag && (
        <BookMarkModal visible={bookMarkFlag} setVisible={setBookMarkFlag} setSelectedBookmark={onAddToBookmark} />
      )}
      <div className="px-4 flex flex-col gap-2 h-full overflow-y-auto">
        <div className={`flex gap-2 flex-wrap mt-6 ${fullView ? 'mt-0' : 'mt-6'}`}>
          {articleDetail.is_relevant ? (
            <span className="text-sm text-green-500 bg-green-50 border border-green-300 px-2 py-0.5 rounded-lg">
              Include
            </span>
          ) : (
            <span className="text-sm text-red-500 bg-red-50 border border-red-300 px-2 py-0.5 rounded-lg">Exclude</span>
          )}
          {articleDetail.nccn_tissue_type && (
            <span className="text-sm text-neutral-700 bg-neutral-50 border border-neutral-300 px-2 py-0.5 rounded-lg flex items-center gap-1">
              <DnaIcon className="w-4 h-4" />
              {articleDetail.nccn_tissue_type}
            </span>
          )}
          {articleDetail.drug && (
            <span className="text-sm text-neutral-700 bg-neutral-50 border border-neutral-300 px-2 py-0.5 rounded-lg flex items-center gap-1">
              <Medicine02Icon className="w-4 h-4" />
              {articleDetail.drug}
            </span>
          )}

          {articleDetail.pubmed_id && (
            <button
              className="text-sm text-neutral-700 bg-neutral-50 border border-neutral-300 px-2 py-0.5 rounded-lg flex items-center gap-1"
              onClick={() => {
                navigator.clipboard.writeText(`${articleDetail.pubmed_id}`);
              }}
            >
              <Copy className="w-4 h-4" />
              PubMed
            </button>
          )}
          {articleDetail.doi && (
            <button
              className="text-sm text-neutral-700 bg-neutral-50 border border-neutral-300 px-2 py-0.5 rounded-lg flex items-center gap-1"
              onClick={() => {
                navigator.clipboard.writeText(articleDetail.doi);
              }}
            >
              <Copy className="w-4 h-4" />
              DOI
            </button>
          )}
        </div>
        <h2 className="text-xl font-semibold ">{articleDetail.title}</h2>

        <div className="flex gap-2 flex-wrap">
          <span className="text-sm text-neutral-500  leading-[160%]">
            <Calendar03Icon className="w-4 h-4 text-neutral-500 inline mr-2" />
            {articleDetail.published_date}
            {articleDetail.authors?.length > 0 && (
              <>
                <UserMultiple02Icon className="w-4 h-4 text-neutral-500  inline mx-2 ml-4" />
                {articleDetail.authors?.join(', ')}
              </>
            )}
          </span>
        </div>
        <div className="flex gap-2 mt-2 flex-wrap">
          {articleDetail.pubmed_id && (
            <Button
              onClick={() => {
                window.open(`https://pubmed.ncbi.nlm.nih.gov/${articleDetail.pubmed_id}`, '_blank');
              }}
            >
              <Link04Icon className="w-4 h-4" />
              PubMed
            </Button>
          )}
          {articleDetail.doi && (
            <Button
              onClick={() => {
                if (articleDetail.doi.startsWith('http')) {
                  window.open(articleDetail.doi, '_blank');
                } else {
                  window.open(`https://doi.org/${articleDetail.doi}`, '_blank');
                }
              }}
            >
              <News01Icon className="w-4 h-4" />
              DOI
            </Button>
          )}

          <Button
            onClick={() => {
              window.open(articleDetail.full_text_url, '_blank');
            }}
            disabled={!articleDetail.full_text_url}
          >
            <LicenseIcon className="w-4 h-4" />
            Full Text
          </Button>
          <Button onClick={() => setPdfViewFlag(true)} disabled={!articleDetail.has_pdf}>
            <Pdf01Icon className="w-4 h-4" />
            View PDF
          </Button>
          <Button onClick={() => setPdfImportFlag(true)}>
            <Upload02Icon className="w-4 h-4" />
            Upload PDF
          </Button>
          {fullView && (
            <Button
              onClick={() => {
                window.open(`/v2/article/${articleId}`, '_blank');
              }}
            >
              <LinkSquare01Icon className="w-4 h-4" />
              Open in New Tab
            </Button>
          )}

          <Button onClick={() => setBookMarkFlag(true)}>
            <Bookmark01Icon className="w-4 h-4" />
            Bookmark
          </Button>

          <Button onClick={() => setFolderFlag(true)}>
            <Folder01Icon className="w-4 h-4" />
            Folder
          </Button>
          <Button onClick={() => {}}>
            <Edit02Icon className="w-4 h-4" />
            Suggest Changes
          </Button>
        </div>
        <div className="flex-1 ">
          <Tabs
            defaultActiveKey="summary"
            className="sticky top-0 bg-white z-10"
            onChange={(key) => {
              // console.log(key);
              setActiveTab(key);
            }}
            activeKey={activeTab}
          >
            <Tabs.TabPane tab="Summary" key="summary" className="text-neutral-600  h-full"></Tabs.TabPane>
            <Tabs.TabPane tab="Abstract" key="abstract" className="h-full"></Tabs.TabPane>
            <Tabs.TabPane
              tab="Extracted Details"
              key="extracted_details"
              className="h-full overflow-y-auto"
            ></Tabs.TabPane>
            <Tabs.TabPane tab="Comments" key="comments" className="h-full overflow-y-auto">
              <CommentSection articleId={articleId} />
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab="LLM Questions" key="llm_questions" className="h-full overflow-y-auto"></Tabs.TabPane> */}
          </Tabs>
          {activeTab == 'summary' && (
            <div className="text-sm text-neutral-600 h-full">
              {SUMMARY_KEY.map((key) => (
                <div key={key} className="my-2">
                  <div className="text-sm font-semibold ">{SUMMARY_KEY_LABEL[key]}</div>
                  <div>{articleDetail?.summary?.[key]}</div>
                </div>
              ))}
            </div>
          )}
          {activeTab == 'abstract' && (
            <div className="text-sm text-neutral-600 overflow-y-auto h-full">{articleDetail?.abstract}</div>
          )}
          {activeTab == 'extracted_details' && articleDetail && <ExtractedDetails article={articleDetail} />}
          {activeTab == 'comments' && <CommentSection articleId={articleId} />}
        </div>
      </div>
    </>
  );
};

export default ArticleView;
