import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { PipelineRunService } from '../../../services/dataService';
import moment from 'moment';
import { Table } from 'antd';

const COLUMN_NAMES = {
  published_year: 'Published Year',
  published_country: 'Published Country',
  is_adhoc: 'Is Ad-Hoc / Post-Hoc',
  study_type: 'Study Type',
  num_patients: 'Number of Patients',
  disease: 'Disease Name',
  drug: 'Drug Name',
  impact_type: 'Impact Type',
  is_combination_therapy: 'Is Combination Therapy',
  is_drug_evaluated: 'Is Drug Evaluated',
  is_relevant: 'Is Relevant',
  nccn_cancer_types: 'NCCN Cancer Type',
  primary_tissue_type: 'Primary Tissue Type',
  first_level_check: 'First Level Filter',
};

const DRUG_CANCER_VALIDATION_COLUMN_NAMES = {
  impact_type_evaluation:"Impact Type",
  is_drug_evaluated_for_disease_evaluation: "Is Drug Evaluated for Disease",
  is_combination_therapy_evaluation: "Is Combination Therapy",
  primary_tissue_type_evaluation: "Primary Tissue Type",
  nccn_cancer_type_evaluation: "NCCN Cancer Type",
}

const GENERIC_VALIDATION_COLUMN_NAMES = {
  diseases: "Diseases",
  drugs: "Drugs",
  endpoints: "Endpoints",
  is_adhoc: "Is Ad-Hoc / Post-Hoc",
  num_patients: "Number of Patients",
  results: "Results",
  study_type: "Study Type",
}


const PipelineRun = () => {
  const [selectedPipelineRun, setSelectedPipelineRun] = useState(null);
  const pieplineRuns = useQuery('pipeline_run', () => PipelineRunService.getAll());
  return (
    <div className="flex flex-col h-full">
      <div>
        <h2 className="text-2xl font-semibold text-neutral-700">Pipeline Runs</h2>
      </div>
      <div className="flex flex-row flex-1 py-8 gap-4 h-full">
        <div className="flex max-w-[400px] flex-col gap-4 basis-1/2  overflow-y-auto">
          {pieplineRuns.data?.map((pipelineRun) => (
            <div
              key={pipelineRun._id}
              className="border border-neutral-200 rounded-md p-4 cursor-pointer"
              onClick={() => setSelectedPipelineRun(pipelineRun)}
            >
              <div className="text-sm text-neutral-800">{pipelineRun.import_date}</div>
              <div className="flex items-center gap-2  mt-2">
                <div
                  className="text-xs text-neutral-500"
                  style={{
                    color:
                      pipelineRun.status == 'completed'
                        ? '#008000'
                        : pipelineRun.status == 'pending'
                          ? '#FFA500'
                          : '#FF0000',
                  }}
                >
                  {pipelineRun.status == 'completed'
                    ? 'Completed'
                    : pipelineRun.status == 'pending'
                      ? 'Pending'
                      : 'Failed'}
                </div>
                <div className="h-4 border-l border-neutral-200"></div>
                <div className="text-xs text-neutral-500">{moment(pipelineRun.created_at).fromNow()}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex-1 flex-col h-full overflow-y-auto">
          {selectedPipelineRun && (
            <div className="border border-neutral-200 rounded-md p-4 ">
              <div className="text-xl text-neutral-800 font-semibold">Import Date: {selectedPipelineRun.import_date}</div>

              <div className="flex flex-col gap-2 mt-4">
                <div className="text-sm text-neutral-600 ">
                  Number of unique articles: {selectedPipelineRun.metrics.total_unique_articles}
                </div>
                <div className="text-sm text-neutral-600 ">
                  Number of drug,disease article pairs: {selectedPipelineRun.metrics.total_unique_drug_article_pairs}
                </div>
              </div>

              <div className="mt-12">
                <div className="text-lg text-neutral-800 font-semibold">Generic Data Validation</div>
                <div className="text-sm text-neutral-600 mt-4">
                  <span className='text-neutral-500'>Total Validated Articles:</span> {selectedPipelineRun.generic_validation.total_validated_articles}
                  </div>
                  <div className="text-sm text-neutral-600 mt-4">
                  <span className='text-neutral-500'>Accuracy:</span> {Math.round(selectedPipelineRun.generic_validation.overall_accuracy * 100)}%
                  </div>

                  {
                    Object.entries(selectedPipelineRun.generic_validation.key_specific_accuracy).map(
                      ([key, value]) => (
                        <div key={key} className="flex flex-col gap-2 my-2">
                          <div className="text-sm text-neutral-600">
                            <span className='text-neutral-500'>{GENERIC_VALIDATION_COLUMN_NAMES[key]}:</span> {Math.round(value * 100)}%
                          </div>
                        </div>
                      )
                    )
                  }
              </div>

              <div className="mt-12">
                <div className="text-lg text-neutral-800 font-semibold">Drug/Disease Data Validation</div>
                <div className="text-sm text-neutral-600 mt-4">
                  <span className='text-neutral-500'>Total Validated Articles:</span> {selectedPipelineRun.drug_cancer_validation.total_validated_articles}
                  </div>
                  <div className="text-sm text-neutral-600 mt-4">
                  <span className='text-neutral-500'>Overall Accuracy:</span> {Math.round(selectedPipelineRun.drug_cancer_validation.overall_accuracy * 100)}%
                  </div>
                  {Object.entries(selectedPipelineRun.drug_cancer_validation.key_specific_accuracy).map(
                    ([key, value]) => (
                      <div key={key} className="flex flex-col gap-2 my-2">
                        <div className="text-sm text-neutral-600">
                          <span className='text-neutral-500'>{DRUG_CANCER_VALIDATION_COLUMN_NAMES[key]}:</span> {Math.round(value * 100)}%
                        </div>
                      </div>
                    )
                  )}
              </div>
              <div className="mt-12">
                <div className="text-lg text-neutral-800 font-semibold">Generic Details Distribution</div>

                {Object.entries(selectedPipelineRun.metrics.generic_details_distribution)
                  .filter(([key, value]) => value.length > 0 && key !== 'num_patients')
                  .map(([key, value]) => (
                    <div key={key} className="flex flex-col gap-2 my-2">
                      {/* <div className="text-sm text-neutral-800 font-semibold">{key}</div> */}
                      <div className="text-sm text-neutral-600">
                        <Table
                          dataSource={value}
                          columns={[
                            {
                              title: COLUMN_NAMES[key],
                              key: key,
                              dataIndex: key,
                              render: (text) => (typeof text === 'boolean' ? (text ? 'Yes' : 'No') : text),
                            },
                            {
                              title: 'count',
                              key: 'count',
                              dataIndex: 'count',
                            },
                          ]}
                          pagination={false}
                        ></Table>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="mt-12">
                <div className="text-lg text-neutral-800 font-semibold">Drug/Disease Details Distribution</div>

                {Object.entries(selectedPipelineRun.metrics.drug_specific_distributions)
                  .filter(([key, value]) => value.length > 0)
                  .map(([key, value]) => (
                    <div key={key} className="flex flex-col gap-2 my-2">
                      {/* <div className="text-sm text-neutral-800 font-semibold">{key}</div> */}
                      <div className="text-sm text-neutral-600">
                        <Table
                          dataSource={value}
                          columns={[
                            {
                              title: COLUMN_NAMES[key],
                              key: key,
                              dataIndex: key,
                              render: (text) => (typeof text === 'boolean' ? (text ? 'Yes' : 'No') : text),
                            },
                            {
                              title: 'count',
                              key: 'count',
                              dataIndex: 'count',
                            },
                          ]}
                          pagination={false}
                        ></Table>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="mt-4">
                <div className="text-sm text-neutral-800 font-semibold">Logs</div>

                <div className="text-sm text-neutral-600 mt-2 flex flex-col gap-2">
                  {selectedPipelineRun.messages.map((message) => (
                    <div key={message.time} className="flex flex-row gap-2">
                      {moment(message.time).format('HH:mm:ss')} - {message.message}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PipelineRun;
