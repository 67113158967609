"use client";

import { useState } from "react";
import { Loader } from "lucide-react";
import { LLMService } from "../../../services/dataService";
import { Button, Input, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { Markdown } from "../../../components/markdown";

export default function PubmedResearch() {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})
  

  const handleSubmit = async () => {

    setLoading(true)
      const response = await LLMService.pubmedResearch({query: query})
      setResponse(response)
      setLoading(false)
  
  }

  return (
    <div className="flex min-h-screen">
      <div className="mx-auto flex w-full flex-col gap-4 rounded-md p-4">
        <div className="flex w-full flex-row gap-2">
          <Input
            placeholder="Search PubMed"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />

          <Button
            loading={loading}
            onClick={() => {
              void handleSubmit();
            }}
          >
            Research
          </Button>
        </div>
        {/* {isLoading && <div>Loading...</div>}
        {error && <div>Error: {error.message}</div>}
        {data?.map((article) => (
          <div key={article.id}>
            <h2 className="text-base font-semibold text-neutral-700">
              {article.title}
            </h2>
            <p className="text-sm text-neutral-400">{article.abstract}</p>
          </div>
        ))} */}
        <Tabs defaultValue="research">
          <TabPane tab="Research" key="research">
          <div value="research">
            {loading && <div className="flex justify-center items-center h-full">
                <Loader className="animate-spin" />
            </div>}
            {response?.error && <div>Error: {response?.error?.message}</div>}
            <div className="flex flex-col gap-8">
          
              <Markdown
                content={response?.finalReport ?? ""}
                allResults={response?.results ?? []}
              />
            </div>
          </div>
          </TabPane>
          <TabPane tab="Articles" key="articles">
          <div value="articles">
            {loading && <div className="flex justify-center items-center h-full">
                <Loader className="animate-spin" />
            </div>}
            <div className="flex flex-col gap-8">
            {response?.results?.map((article) => (
              <div key={article.id}>
                <h2 className="text-base font-semibold text-neutral-700">
                  <a href={`https://pubmed.ncbi.nlm.nih.gov/${article.id}`} target="_blank" rel="noopener noreferrer">
                    {article.title}
                  </a>
                </h2>
                <p className="text-sm text-neutral-400">{article.abstract}</p>
              </div>
              ))}
            </div>
            </div>
          </TabPane>

         
 
        </Tabs>
      </div>
    </div>
  );
}
