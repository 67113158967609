import { Tabs, Input, Spin, Button } from "antd";
import { useState } from "react";
import { LLMService } from "../../../services/dataService";
import ReactMarkdown from "react-markdown";
import { User, Bot, Send } from "lucide-react";
import remarkGfm from 'remark-gfm'
import PubmedResearch from "./pubmedResearch";
import PubmedGraph from "./graph";

export const UserMessageComponent = ({message}) => {
    return <div className="flex flex-row items-center justify-end">
      <div className="flex flex-row items-center bg-blue-100 p-2 rounded-lg text-blue-600">
       <div className="block">
        <ReactMarkdown className="text-sm">{message.content}</ReactMarkdown>
        </div>
        <div className="text-sm text-blue-600 ml-2" >
         <User className=" h-5 w-5"/>
        </div>
        </div>
    </div>
}

export const AgentMessageComponent = ({message}) => {
    return <div className="flex flex-row items-center">
      <div className="flex flex-row  bg-neutral-100 p-2 rounded-lg gap-2 text-neutral-700">
        <div className="text-sm text-neutral-800">
         <Bot className=" h-5 w-5"/>
        </div>
        <div className="block">
        <ReactMarkdown
        transformLinkUri={(uri) => {
          return uri
        }}
        remarkPlugins={[remarkGfm]}
        components={{
          p: ({children}) => <p className="text-sm">{children}</p>,
          ol: ({children}) => <ol className="text-sm list-decimal py-2 pl-4">{children}</ol>,
          ul: ({children}) => <ul className="text-sm list-disc py-2 pl-4">{children}</ul>,
          li: ({children}) => <li className="text-sm">{children}</li>,
          a: ({children, href}) => {
            if (href.startsWith("pmid://")) {
              const pmid = href.split("pmid://")[1]
              return <a className="text-sm text-blue-500" href={`https://pubmed.ncbi.nlm.nih.gov/${pmid}`} target="_blank" rel="noopener noreferrer">[{pmid}]</a>
            }else{
            return <a className="text-sm text-blue-500" href={href} target="_blank" rel="noopener noreferrer">{children}</a>
            }
          }
        }}
         >{message.content}</ReactMarkdown>
        </div>
        </div>
    </div>
}


export const ChatExperiment = () => {
    const [messages, setMessages] = useState([])
    const [input, setInput] = useState("")
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState("")

    const handleSubmit = async (e) => {
      if (e.key === "Enter") {
      // Check if shift key is pressed
      if (e.shiftKey) {
        return
      }

        e.preventDefault()
        setLoading(true)
        setInput("")
        const newMessage = {role: "human", content: input}
        setMessages(prev => [...prev, newMessage])
        const response = await LLMService.kgChat({messages: [...messages, newMessage]})
        setMessages(prev => [...prev, response])
        setLoading(false)
    }
    }

   
  return (
    <div className="h-[calc(100vh-180px)] w-full relative overflow-y-auto">
        <div className="flex flex-col h-[calc(100vh-180px)]  max-w-[700px] mx-auto gap-4 overflow-y-auto pb-32 px-4">
            {messages.map((message, index) => (
                <div key={index} className="flex flex-col">
                    {message.role === "human" ? <UserMessageComponent message={message} /> : <AgentMessageComponent message={message} />}
                </div>
            ))}
        </div>
      <div className="absolute bottom-0 mb-2 w-full ">
        <div className="flex justify-center max-w-[700px] mx-auto relative">
          <Input.TextArea
            placeholder="Enter your query"
            className="w-full border border-blue-500"
            style={{
              height: "72px"
            }}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleSubmit}
          />
    
          <Button onClick={handleSubmit} 
          className="absolute bottom-2 left-2 w-10 flex items-center justify-center" 
          size="small" 
          style={{
            width: "40px",
          }}
          type="primary" loading={loading}>
            {!loading && <Send className="h-3 w-3 text-white" />}
          </Button>
        </div>
        
      </div>
    </div>
  );
};

export const KnowledgeGraphExperiment = () => {
  return <div className="h-full">KnowledgeGraphExperiment</div>;
};

export const PubmedAgentExperiment = () => {
  return <div className="h-full">PubmedAgentExperiment</div>;
};

export default function Experiment() {
  return (
    <div className="flex flex-col gap-2 h-full">
      <h1 className="text-xl font-semibold text-neutral-800 basis-[20px]">Experiments</h1>
      <Tabs className="basis-full h-full">
        <Tabs.TabPane tab="Chat" key="chat" className="h-full w-full">
          <ChatExperiment />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Knowledge Graph" key="kg" className="h-full w-full">
          {/* <KnowledgeGraphExperiment /> */}
          <PubmedGraph />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pubmed Agent" key="pubmed-agent" className="h-full w-full">
          {/* <PubmedAgentExperiment /> */}
          <PubmedResearch />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
