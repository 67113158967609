import { Loader } from "lucide-react";
import ReactFlow, { Node, Edge, Controls, Background, Position } from "reactflow";
import "reactflow/dist/style.css";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { Button, Input } from "antd";
import { useMutation } from "react-query";
import { LLMService } from "../../../services/dataService";

const GraphVis = ({
  data,
}) => {
  console.log(data);
  const flowNodes = [];
  const flowEdges = [];

  // Calculate center of the viewport
  const centerX = 0;
  const centerY = 0;
  const radius = 300; // Distance from center to surrounding nodes

  // Place article node in center
  flowNodes.push({
    id: data.nodes.document.id,
    data: { label: data.nodes.document.title },
    position: { x: centerX, y: centerY },
    type: "default",
    style: {
      background: "#fff",
      border: "2px solid #777",
      borderRadius: "8px",
      padding: "10px",
    },
  });

  // Helper function to calculate positions in an arc
  const getPositionsInArc = (
    index,
    total,
    startAngle,
    endAngle,
  ) => {
    const angleRange = endAngle - startAngle;
    const angle = startAngle + (angleRange * index) / (total - 1 || 1);
    return {
      x: centerX + radius * Math.cos(angle),
      y: centerY + radius * Math.sin(angle),
    };
  };

  // Position nodes in sectors
  // Drugs in right sector (330° to 30°)
  data.nodes.drugs.forEach((drug, index) => {
    const position = getPositionsInArc(
      index,
      data.nodes.drugs.length,
      -Math.PI / 6, // -30°
      Math.PI / 6, // 30°
    );
    flowNodes.push({
      id: drug.id,
      data: { label: drug.name },
      position,
      type: "default",
      style: { background: "#e6f3ff", borderRadius: "8px" },
    });
  });

  // Diseases in top sector (60° to 120°)
  data.nodes.cancer_diseases.forEach((disease, index) => {
    const position = getPositionsInArc(
      index,
      data.nodes.cancer_diseases.length,
      Math.PI / 3, // 60°
      (2 * Math.PI) / 3, // 120°
    );
    flowNodes.push({
      id: disease.id,
      data: { label: disease.name },
      position,
      type: "default",
      style: { background: "#ffe6e6", borderRadius: "8px" },
    });
  });

  // Endpoints in left sector (150° to 210°)
  data.nodes.endpoints.forEach((endpoint, index) => {
    const position = getPositionsInArc(
      index,
      data.nodes.endpoints.length,
      (5 * Math.PI) / 6, // 150°
      (7 * Math.PI) / 6, // 210°
    );
    flowNodes.push({
      id: endpoint.id,
      data: { label: endpoint.name },
      position,
      type: "default",
      style: { background: "#e6ffe6", borderRadius: "8px" },
      
    });
  });

  // // Results in bottom sector (240° to 300°)
  // data.nodes.results.forEach((result, index) => {
  //   const position = getPositionsInArc(
  //     index,
  //     data.nodes.results.length,
  //     (4 * Math.PI) / 3, // 240°
  //     (5 * Math.PI) / 3, // 300°
  //   );
  //   flowNodes.push({
  //     id: result.id,
  //     data: { label: `${result.description} (${result.impact})` },
  //     position,
  //     type: "default",
  //     style: { background: "#fff0e6", borderRadius: "8px" },
  //   });
  // });


  // Add edges with consistent styling
  data.edges.article_mentions_drug.forEach((edge) =>
    flowEdges.push({
      id: `${edge.article_id}-${edge.drug_id}`,
      source: edge.article_id,
      target: edge.drug_id,
      label: "mentions",
      style: { stroke: "#999" },
      // animated: true,
      // type: "floating",
      sourceHandle: "left",
      targetHandle: "right",
    }),
  );

  data.edges.article_evaluates_drug.forEach((edge) =>
    // flowEdges.push(createEdge(edge, edge.drug_id, "evaluates")),
    flowEdges.push({
      id: `${edge.article_id}-${edge.drug_id}-evaluates`,
      source: edge.article_id,
      target: edge.drug_id,
      // label: "evaluates",
      style: { stroke: "#999" },
      // animated: true,
      // type: "floating",
      sourceHandle: "left",
      targetHandle: "right",
    }),
  );

  data.edges.article_evaluates_endpoint.forEach((edge) =>
    flowEdges.push({
      id: `${edge.article_id}-${edge.endpoint_id}`,
      source: edge.article_id,
      target: edge.endpoint_id,
      label: "evaluates",
      style: { stroke: "#999" },
      animated: true,
      type: "floating",
      sourceHandle: "right",
      targetHandle: "left",
    }),
  );

  data.edges.drug_combination_drug.forEach((edge) =>
    flowEdges.push({
      id: `${edge.drug_id_1}-${edge.drug_id_2}-combination`,
      source: edge.drug_id_1,
      target: edge.drug_id_2,
      label: "combination",
      style: { stroke: "#999" },
      animated: true,
      type: "floating",
      sourceHandle: "right",
      targetHandle: "right",
    }),
  );

//   data.edges.endpointHasResult.forEach((edge) =>
//     flowEdges.push({
//       id: `${edge.endpoint_id}-${edge.result_id}`,
//       source: edge.endpoint_id,
//       target: edge.result_id,
//       label: "has result",
//       style: { stroke: "#999" },
//       animated: true,
//     }),
//   );

  data.edges.mentions_disease.forEach((edge) =>
    // flowEdges.push(createEdge(edge, edge.disease_id, "mentions")),
    flowEdges.push({
      id: `${edge.article_id}-${edge.disease_id}-mentions`,
      source: edge.article_id,
      target: edge.disease_id,
      label: "mentions",
      style: { stroke: "#999" },
      animated: true,
      type: "floating",
      sourceHandle: "bottom",
      targetHandle: "top",
    }),
  );

  data.edges.study_disease.forEach((edge) =>
    // flowEdges.push(createEdge(edge, edge.disease_id, "studies")),
    flowEdges.push({
      id: `${edge.article_id}-${edge.disease_id}-studies`,
      source: edge.article_id,
      target: edge.disease_id,
      label: "studies",
      style: { stroke: "#999" },
      animated: true,
      type: "floating",
      sourceHandle: "bottom",
      targetHandle: "top",
    }),
  );

  console.log(flowNodes, flowEdges);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactFlow
        nodes={flowNodes}
        edges={flowEdges}
        fitView
        minZoom={0.2}
        maxZoom={1.5}
        defaultEdgeOptions={{
            type: "smoothstep",
            animated: true,
          }}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default function PubmedGraph() {
  const [pmid, setPmid] = useState("");
  const graphMutation = useMutation(
    (pmid) => LLMService.pubmedGraph({ pmid }),
  );
  
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const pmid = e.currentTarget.value;
      void graphMutation.mutateAsync(pmid );
    }
  };

  return (
    <div className="h-full w-full">
      <div className="mx-auto max-w-screen-md p-4">
        <div className="flex flex-row gap-2">
          <Input placeholder="Enter PMID" onKeyDown={handleKeyDown}
          value={pmid}
          onChange={(e) => setPmid(e.target.value)}
          />
          <Button onClick={() => graphMutation.mutate(pmid)}
          loading={graphMutation.isPending}
          >
            Extract
          </Button>
        </div>
        {graphMutation.isPending && <Loader className="animate-spin" />}
        <div className="h-[500px]">
          {graphMutation.data && <GraphVis data={graphMutation.data} />}
        </div>
        {graphMutation.data && <ReactJson src={graphMutation.data} />}
      </div>
    </div>
  );
}
