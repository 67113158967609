import React from "react";
import { ValidationTaskV2 } from "../../../services/dataService";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Button } from "../../components/button";

const ValidationSetCard = ({ set }) => {
  return (
    <Link to={`/v2/validation/${set._id}`}>
      <div className="card w-full p-4 border border-neutral-200 rounded-lg">
        <h3 className="text-base font-semibold text-neutral-800">{set.name}</h3>
      </div>
    </Link>
  );
};
const Validation = () => {
  const valTask = useQuery(["validation_sets"], () =>
    ValidationTaskV2.getSets().then((res) => res)
  );

  const exportDataMutation = useMutation((data) =>
    ValidationTaskV2.exportData(data).then((res) => res)
  );

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="text-2xl font-semibold text-neutral-800 mt-6">Validation</h1>
        <div>
          <Button
            onClick={() => exportDataMutation.mutate()}
            loading={exportDataMutation.isLoading}
            className="bg-neutral-800 text-white px-4 py-2 hover:bg-neutral-600"
          >
            Export
          </Button>
        </div>
      </div>
      {valTask.isLoading && <div>Loading...</div>}

      <div className="grid grid-cols-3 gap-4 mt-12">
        {valTask.data &&
          valTask.data.map((set) => (
            <div key={set._id} className="col-span-1">
              <ValidationSetCard set={set} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Validation;
